<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.current_stock_report') }}</h4>
        </template>
      <template v-slot:body>
        <b-overlay>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
        <b-row class="pl-5">
          <b-col cols="9">
            <ValidationProvider>
            <b-form-group
            class="row"
            label-cols-sm="0"
            label-for="amount"
            slot-scope="{ valid, errors }"
            >
            <b-form-radio-group
            v-model="search.report_type"
            :options="options"
            class="mb-3"
            value-field="item"
            text-field="name"
            :state="errors[0] ? false : (valid ? true : null)"
            >
            </b-form-radio-group>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
           <b-col cols="3">
             <b-button type="submit"  variant="primary float-right">{{ $t('globalTrans.submit')}}</b-button>
          </b-col>
        </b-row>
        </b-form>
        </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
      <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('fertilizerReport.current_stock_report') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button class="btn_add_new" @click="pdfExport">
                <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
              </b-button>
            </template>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                <b-row v-show="!loading">
                    <b-col>
                        <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                          {{ $t('fertilizerReport.current_stock_report')}}
                        </list-report-head>
                    </b-col>
                  </b-row>
                <b-row>
                  <b-col md="12" class="table-responsive">
                    <h4 class="float-right text-black">{{ $t('fertilizerReport.amountTon') }}</h4>
                    <b-overlay v-show="totStock">
                    <b-table thead-class="table_head" striped bordered hover :items="stockDataTotalStock" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                      <template v-slot:cell(index)="stockData">
                        {{ $n(stockData.item.index) }}
                      </template>
                      <template v-for="(key,i) in totalStockReportKeys" v-slot:[gomycell(key)]="stockData">
                        <span class="capitalize" :key="i">{{ $n(stockData.item[key]) }}</span>
                      </template>
                      <template v-slot:cell(total_amount)="stockData">
                        <span class="capitalize">{{ $n(stockData.item.total_amount) }}</span>
                      </template>
                      <template #custom-foot="">
                        <b-tr>
                          <b-th class="text-right" colspan="2"><span>{{ $t('sitePreference.grossTotal') }}</span></b-th>
                          <b-th v-for="(fertilizerName,i) in fertilizerNameList" :key="i" class="text-right">
                            {{ $n(totalStockReportTotalObj[fertilizerName.value]) }}
                          </b-th>
                          <b-th class="text-right">
                            {{ $n(sum(totalStockReportTotalObj)) }}
                          </b-th>
                        </b-tr>
                      </template>
                    </b-table>
                    </b-overlay>
                     <b-overlay v-show="godownTable">
<!--                      <b-table bordered hover :items="stockData" :fields="godownColumn" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>-->
<!--                        <template v-slot:cell(index)="data">-->
<!--                          {{ $n(data.index + 1) }}-->
<!--                        </template>-->
<!--                        <template v-slot:cell(tot_complain)="data">-->
<!--                          <span class="capitalize">{{ $n(data.item.tot_complain) }}</span>-->
<!--                        </template>-->
<!--                      </b-table>-->
                       <b-table-simple bordered hover small caption-top responsive>
                         <b-thead>
                           <b-tr>
                             <b-th style="width:10%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                             <b-th style="width:10%" class="text-center">{{ $t('globalTrans.region') }}</b-th>
                             <b-th style="width:10%" class="text-center">{{ $t('globalTrans.nong') }}</b-th>
                             <b-th style="width:20%" class="text-center">{{ $t('fertilizerReport.godown') }}</b-th>
                             <b-th style="width:10%" class="text-right" v-for="(fertilizer, index) in fertilizerNameList" :key="index">{{ currentLocale === 'en' ? fertilizer.text_en : fertilizer.text_bn }}</b-th>
                             <b-th style="width:10%" class="text-right">{{ $t('fertilizerReport.total') }}</b-th>
                           </b-tr>
                         </b-thead>
                         <b-tbody>
                           <template v-for="(stockDataItem, stockDataIndex) in stockDataGodownWiseTotalStock">
                             <template v-for="(regionDataItem, regionDataIndex) in stockDataItem.region_data">
                               <b-tr :key="stockDataIndex+regionDataIndex" v-if="regionDataIndex === 0">
                                 <b-th style="text-align:center" :rowspan="stockDataItem.region_data.length">
                                   {{ $n(stockDataIndex + 1) }}
                                 </b-th>
                                 <b-th style="text-align:center" :rowspan="stockDataItem.region_data.length">
                                   {{ currentLocale === 'en' ? stockDataItem.region_name : stockDataItem.region_name_bn }}
                                 </b-th>
                                 <b-th style="text-align:center">
                                   {{ $n(regionDataIndex + 1) }}
                                 </b-th>
                                 <b-th style="text-align:center">
                                   {{ currentLocale === 'en' ? regionDataItem.godown_infos_name : regionDataItem.godown_infos_name_bn }}
                                 </b-th>
                                 <b-th v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                                  {{ getFertilizerAmount(regionDataItem.godown_infos_data, fertilizerName.value) }}
                                 </b-th>
                                 <b-th class="text-right">{{ $n(regionDataItem.godown_infos_total) }}</b-th>
                               </b-tr>
                               <b-tr :key="stockDataIndex+(regionDataIndex+5555)" v-else>
                                 <b-th style="text-align:center">
                                   {{ $n(regionDataIndex + 1) }}
                                 </b-th>
                                 <b-th style="text-align:center">
                                   {{ currentLocale === 'en' ? regionDataItem.godown_infos_name : regionDataItem.godown_infos_name_bn }}
                                 </b-th>
                                 <b-th v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                                   {{ getFertilizerAmount(regionDataItem.godown_infos_data, fertilizerName.value) }}
                                 </b-th>
                                 <b-th class="text-right">{{ $n(regionDataItem.godown_infos_total) }}</b-th>
                               </b-tr>
                             </template>
                             <template>
                               <b-tr :key="stockDataIndex+100">
                                 <b-th style="text-align:center" colspan="4" class="text-right">
                                   {{ currentLocale === 'en' ? stockDataItem.region_name : stockDataItem.region_name_bn }} {{ $t('globalTrans.total') }}
                                 </b-th>
                                 <b-th v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                                   {{ $n(stockDataItem.region_total_obj[fertilizerName.value]) }}
                                 </b-th>
                                 <b-th class="text-right">{{ $n(sum(stockDataItem.region_total_obj)) }}</b-th>
                               </b-tr>
                             </template>
                           </template>
                           <b-tr>
                             <b-th style="text-align:center" colspan="4" class="text-right">
                                {{ $t('globalTrans.total') }}
                             </b-th>
                             <b-th v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                               {{ $n(godownWiseTotalStockTotalObj[fertilizerName.value]) }}
                             </b-th>
                             <b-th class="text-right">{{ $n(sum(godownWiseTotalStockTotalObj)) }}</b-th>
                           </b-tr>
                           <b-tr>
                             <b-th style="text-align:center" colspan="4" class="text-right">
                               {{ $t('fertilizerReport.transported') }}
                             </b-th>
                             <b-th v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                               {{ $n(totalTranstportedInfoTotalObj[fertilizerName.value]) }}
                             </b-th>
                             <b-th class="text-right">{{ $n(sum(totalTranstportedInfoTotalObj)) }}</b-th>
                           </b-tr>
                         </b-tbody>
                         <b-tfoot>
                           <b-tr>
                             <b-th style="text-align:center" colspan="4" class="text-right">
                               {{ $t('sitePreference.grossTotal') }}
                             </b-th>
                             <b-th v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                               {{ $n(godownWiseTotalStockGrandTotalObj[fertilizerName.value]) }}
                             </b-th>
                             <b-th class="text-right">{{ $n(sum(godownWiseTotalStockGrandTotalObj)) }}</b-th>
                           </b-tr>
                         </b-tfoot>
                       </b-table-simple>
                    </b-overlay>
                     <b-overlay v-show="regionTable">
<!--                      <b-table bordered hover :items="stockData" :fields="regionColumn" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>-->
<!--                        <template v-slot:cell(index)="data">-->
<!--                          {{ $n(data.index + 1) }}-->
<!--                        </template>-->
<!--                        <template v-slot:cell(tot_complain)="data">-->
<!--                          <span class="capitalize">{{ $n(data.item.tot_complain) }}</span>-->
<!--                        </template>-->
<!--                      </b-table>-->
                       <b-table-simple bordered hover small caption-top responsive>
                         <b-thead>
                           <b-tr>
                             <b-th style="width:10%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                             <b-th style="width:10%" class="text-center">{{ $t('globalTrans.region') }}</b-th>
                             <b-th style="width:10%" class="text-right" v-for="(fertilizer, index) in fertilizerNameList" :key="index">{{ currentLocale === 'en' ? fertilizer.text_en : fertilizer.text_bn }}</b-th>
                             <b-th style="width:10%" class="text-right">{{ $t('fertilizerReport.total') }}</b-th>
                           </b-tr>
                         </b-thead>
                         <b-tbody>
                             <b-tr v-for="(stockDataItem, stockDataIndex) in stockDataRegionWiseTotalStock" :key="stockDataIndex">
                               <b-th style="text-align:center">
                                 {{ $n(stockDataIndex + 1) }}
                               </b-th>
                               <b-th style="text-align:center">
                                 {{ currentLocale === 'en' ? stockDataItem.region_name : stockDataItem.region_name_bn }}
                               </b-th>
                               <b-th v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                                 {{ $n(stockDataItem.region_total_obj[fertilizerName.value]) }}
                               </b-th>
                               <b-th class="text-right">{{ $n(sum(stockDataItem.region_total_obj)) }}</b-th>
                             </b-tr>
                             <b-tr>
                               <b-th style="text-align:center" colspan="2" class="text-right">
                                 {{ $t('globalTrans.total') }}
                               </b-th>
                               <b-th v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                                 {{ $n(regionWiseTotalStockTotalObj[fertilizerName.value]) }}
                               </b-th>
                               <b-th class="text-right">{{ $n(sum(regionWiseTotalStockTotalObj)) }}</b-th>
                             </b-tr>
                             <b-tr>
                               <b-th style="text-align:center" colspan="2" class="text-right">
                                 {{ $t('fertilizerReport.transported') }}
                               </b-th>
                               <b-th v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                                 {{ $n(totalTranstportedInfoTotalObj[fertilizerName.value]) }}
                               </b-th>
                               <b-th class="text-right">{{ $n(sum(totalTranstportedInfoTotalObj)) }}</b-th>
                             </b-tr>
                         </b-tbody>
                         <b-tfoot>
                           <b-tr>
                             <b-th style="text-align:center" colspan="2" class="text-right">
                               {{ $t('sitePreference.grossTotal') }}
                             </b-th>
                             <b-th v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                               {{ $n(regionWiseTotalStockGrandTotalObj[fertilizerName.value]) }}
                             </b-th>
                             <b-th class="text-right">{{ $n(sum(regionWiseTotalStockGrandTotalObj)) }}</b-th>
                           </b-tr>
                         </b-tfoot>
                       </b-table-simple>
                    </b-overlay>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, currentStockReport } from '../../api/routes'
// import ExportPdf from '@/Utils/export-pdf'
import ExportPdf from './export-pdf_details'
import ListReportHead from '@/components/custom/ListReportHead.vue'

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      showData: false,
      regionTable: false,
      godownTable: false,
      totStock: false,
      search: {
        report_type: 1,
        org_id: 3
      },
      options: [
          { item: 1, name: this.$t('fertilizerReport.tot_stock') },
          { item: 2, name: this.$t('fertilizerReport.godownWiseTotalStock') },
          { item: 3, name: this.$t('fertilizerReport.regionWiseTotalStock') }
        ],
      region_name: '',
      region_name_bn: '',
      currentDate: '',
      organization: [],
      testId: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      farmerList: [],
      warehouseList: [],
      regionDistrictLists: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      totalStockReportTotalObj: {},
      totalStockReportKeys: [],
      totalTranstportedInfoTotalObj: {},
      godownWiseTotalStockTotalObj: {},
      godownWiseTotalStockGrandTotalObj: {},
      regionWiseTotalStockTotalObj: {},
      regionWiseTotalStockGrandTotalObj: {},
      stockData: [],
      stockDataTotalStock: [],
      stockDataGodownWiseTotalStock: [],
      stockDataRegionWiseTotalStock: []
    }
  },
  computed: {
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    regionList: function () {
      return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
    },
    formTitle () {
       return this.$t('fertilizerReport.current_stock_report')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('fertilizerConfig.description'), class: 'text-left' }
        ]
        let keys = []
        keys = [
          { key: 'index' },
          { key: this.currentLocale === 'en' ? 'description' : 'description_bn' }
        ]
        this.fertilizerNameList.filter(item => {
          const labelItem = {
            label: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en,
            class: 'text-left'
          }
          labels.push(labelItem)
          keys.push({ key: 'fertilizer_amount_' + item.value, class: 'text-right' })
        })
        labels.push({ label: this.$t('fertilizerReport.total'), class: 'text-left' })
        keys.push({ key: 'total_amount', class: 'text-right' })

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    godownColumn () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('fertilizerReport.godownName'), class: 'text-left' }
        ]
        let keys = []
        keys = [
          { key: 'index' }
        ]
        keys.push({ key: 'godownName' })
        this.fertilizerNameList.filter(item => {
          const labelItem = {
            label: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en,
            class: 'text-left'
          }
          labels.push(labelItem)
          keys.push({ key: 'fertilizer_amount_' + item.value })
        })
        labels.push({ label: this.$t('fertilizerReport.total'), class: 'text-left' })
        keys.push({ key: 'total_amount' })

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    regionColumn () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('fertilizerReport.region'), class: 'text-left' }
        ]
        let keys = []
        keys = [
          { key: 'index' }
        ]
        keys.push({ key: 'godownName' })
        this.fertilizerNameList.filter(item => {
          const labelItem = {
            label: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en,
            class: 'text-left'
          }
          labels.push(labelItem)
          keys.push({ key: 'fertilizer_amount_' + item.value })
        })
        labels.push({ label: this.$t('fertilizerReport.total'), class: 'text-left' })
        keys.push({ key: 'total_amount' })

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  created () {
  },
  mounted () {
    core.index()
  },
  methods: {
    sum (obj) {
      var sum = 0
      for (var el in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, el)) {
          sum += parseFloat(obj[el])
        }
      }
      return sum
    },
    gomycell (key) {
      return `cell(${key})`
    },
    getFertilizerAmount (fertilizerData, fertilizerId) {
      const fertilizerDataItem = fertilizerData.find(fertilizer => fertilizer.fertilizer_id === fertilizerId)
      return typeof fertilizerDataItem !== 'undefined' ? this.$n(fertilizerDataItem.amount) : ''
    },
    searchData () {
      this.stockData = []
      this.totalStockReportTotalObj = {}
      this.totalStockReportKeys = []
      this.totalTranstportedInfoTotalObj = {}
      this.godownWiseTotalStockTotalObj = {}
      this.godownWiseTotalStockGrandTotalObj = {}
      this.regionWiseTotalStockTotalObj = {}
      this.regionWiseTotalStockGrandTotalObj = {}
      this.showData = true
      if (this.search.report_type === 1) {
        this.godownTable = false
        this.regionTable = false
        this.totStock = true
      }
      if (this.search.report_type === 2) {
        this.regionTable = false
        this.totStock = false
        this.godownTable = true
      }
      if (this.search.report_type === 3) {
         this.totStock = false
        this.godownTable = false
        this.regionTable = true
      }
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.currentDate = date
      const params = Object.assign({}, this.search)
      await RestApi.getData(seedFertilizerServiceBaseUrl, currentStockReport, params).then(response => {
        if (response.success) {
          const result = response.current_stock_data
          const transportData = response.transported_stock_data
          if (this.search.report_type === 1) {
            let totalAmount = 0
            let transportAmount = 0
            const listItem = {
              index: 1
            }
            const transportListItem = {
              index: 2
            }
            this.fertilizerNameList.filter(item => {
              listItem['fertilizer_amount_' + item.value] = 0
              if (typeof this.totalStockReportTotalObj[item.value] === 'undefined') {
                this.totalStockReportTotalObj[item.value] = 0
              }
              if (result.length) {
                result.filter(resultItem => {
                  if (item.value === resultItem.id) {
                    listItem['fertilizer_amount_' + item.value] = resultItem.amount
                    totalAmount += resultItem.amount
                    this.totalStockReportTotalObj[item.value] += resultItem.amount
                  }
                })
              }
              this.totalStockReportKeys.push('fertilizer_amount_' + item.value)
            })
            listItem.total_amount = totalAmount
            listItem.description = 'Stock In Godown'
            listItem.description_bn = 'গুদামে মজুদ'
            this.fertilizerNameList.filter(item => {
              transportListItem['fertilizer_amount_' + item.value] = 0
              if (typeof this.totalStockReportTotalObj[item.value] === 'undefined') {
                this.totalStockReportTotalObj[item.value] = 0
              }
              if (transportData.length) {
                transportData.filter(resultItem => {
                  if (item.value === resultItem.fertilizer_id) {
                    transportListItem['fertilizer_amount_' + item.value] = resultItem.amount
                    transportAmount += resultItem.amount
                    this.totalStockReportTotalObj[item.value] += resultItem.amount
                  }
                })
              }
            })
            transportListItem.description = 'Transported'
            transportListItem.description_bn = 'পরিবহনাধীন'
            transportListItem.total_amount = transportAmount
            const stockData = [listItem]
            stockData.push(transportListItem)
            this.stockData = stockData
            this.stockDataTotalStock = stockData
          }

          if (this.search.report_type >= 2) {
            this.fertilizerNameList.filter(item => {
              if (typeof this.totalTranstportedInfoTotalObj[item.value] === 'undefined') {
                this.totalTranstportedInfoTotalObj[item.value] = 0
                this.godownWiseTotalStockGrandTotalObj[item.value] = 0
                this.regionWiseTotalStockGrandTotalObj[item.value] = 0
              }
              if (transportData.length) {
                transportData.filter(resultItem => {
                  if (item.value === resultItem.fertilizer_id) {
                    this.totalTranstportedInfoTotalObj[item.value] += resultItem.amount
                    this.godownWiseTotalStockGrandTotalObj[item.value] += resultItem.amount
                    this.regionWiseTotalStockGrandTotalObj[item.value] += resultItem.amount
                  }
                })
              }
            })
          }

          if (this.search.report_type === 2) {
            this.stockData = result
            const reportData = []
            if (result.length) {
              result.filter(item => {
                let regionItemObj = reportData.find(regionItem => regionItem.region_id === item.region_id)
                if (typeof regionItemObj === 'undefined') {
                  const region = this.regionList.find(region => region.value === item.region_id)
                  let regionName, regionNameBn
                  if (typeof region !== 'undefined') {
                    regionName = region.text_en
                    regionNameBn = region.text_bn
                  } else {
                    regionName = ''
                    regionNameBn = ''
                  }
                  regionItemObj = {
                    region_id: item.region_id,
                    region_name: regionName,
                    region_name_bn: regionNameBn,
                    region_data: []
                  }
                }
                let godownInfoItemObj = regionItemObj.region_data.find(regionInnerItem => regionInnerItem.godown_infos_id === item.godown_infos_id)
                if (typeof godownInfoItemObj === 'undefined') {
                  const godown = this.godownInfoList.find(godown => godown.value === item.godown_infos_id)
                  let godownName, godownNameBn
                  if (typeof godown !== 'undefined') {
                    godownName = godown.text_en
                    godownNameBn = godown.text_bn
                  } else {
                    godownName = ''
                    godownNameBn = ''
                  }
                  godownInfoItemObj = {
                    godown_infos_id: item.godown_infos_id,
                    godown_infos_name: godownName,
                    godown_infos_name_bn: godownNameBn,
                    godown_infos_data: []
                  }
                }
                const fertilizerItem = {}
                fertilizerItem.fertilizer_id = item.id
                fertilizerItem.amount = item.amount
                godownInfoItemObj.godown_infos_data.push(fertilizerItem)
                let godownInfosTotal = 0
                godownInfoItemObj.godown_infos_data.filter(godownInfosDataItem => {
                  godownInfosTotal += godownInfosDataItem.amount
                })
                godownInfoItemObj.godown_infos_total = godownInfosTotal
                const godownInfoIndex = regionItemObj.region_data.findIndex(godownInfoItem => godownInfoItem.godown_infos_id === item.godown_infos_id)
                if (godownInfoIndex !== -1) {
                  regionItemObj.region_data.splice(godownInfoIndex, 1)
                }
                regionItemObj.region_data.push(godownInfoItemObj)
                regionItemObj.region_total_obj = {}
                this.fertilizerNameList.filter(fertilizerName => {
                  let totalAmount = 0
                  regionItemObj.region_data.filter(godownObj => {
                    const fertilizerObj = godownObj.godown_infos_data.find(fertilizerObj => fertilizerObj.fertilizer_id === fertilizerName.value)
                    if (typeof fertilizerObj !== 'undefined') {
                      totalAmount += fertilizerObj.amount
                    }
                  })
                  regionItemObj.region_total_obj[fertilizerName.value] = totalAmount
                })
                const regionItemIndex = reportData.findIndex(reportDataItem => reportDataItem.region_id === item.region_id)
                if (regionItemIndex !== -1) {
                  reportData.splice(regionItemIndex, 1)
                }
                reportData.push(regionItemObj)
              })
              this.stockData = reportData
              this.stockDataGodownWiseTotalStock = reportData
              this.fertilizerNameList.filter(fertilizerName => {
                let totalAmount = 0
                this.stockData.filter(regionItem => {
                  regionItem.region_data.filter(godownItem => {
                    const fertilizer = godownItem.godown_infos_data.find(fertilizerItem => fertilizerItem.fertilizer_id === fertilizerName.value)
                    if (typeof fertilizer !== 'undefined') {
                      totalAmount += fertilizer.amount
                    }
                  })
                })
                this.godownWiseTotalStockGrandTotalObj[fertilizerName.value] += totalAmount
                this.godownWiseTotalStockTotalObj[fertilizerName.value] = totalAmount
              })
            }
          }

          if (this.search.report_type === 3) {
            this.stockData = result
            const reportData = []
            if (result.length) {
              result.filter(item => {
                let regionItemObj = reportData.find(regionItem => regionItem.region_id === item.region_id)
                if (typeof regionItemObj === 'undefined') {
                  const region = this.regionList.find(region => region.value === item.region_id)
                  let regionName, regionNameBn
                  if (typeof region !== 'undefined') {
                    regionName = region.text_en
                    regionNameBn = region.text_bn
                  } else {
                    regionName = ''
                    regionNameBn = ''
                  }
                  regionItemObj = {
                    region_id: item.region_id,
                    region_name: regionName,
                    region_name_bn: regionNameBn,
                    region_data: []
                  }
                }
                const fertilizerItem = {}
                fertilizerItem.fertilizer_id = item.id
                fertilizerItem.amount = item.amount
                regionItemObj.region_data.push(fertilizerItem)
                regionItemObj.region_total_obj = {}
                this.fertilizerNameList.filter(fertilizerName => {
                  let totalAmount = 0
                  const fertilizerObj = regionItemObj.region_data.find(fertilizerObj => fertilizerObj.fertilizer_id === fertilizerName.value)
                  if (typeof fertilizerObj !== 'undefined') {
                    totalAmount += fertilizerObj.amount
                  }
                  regionItemObj.region_total_obj[fertilizerName.value] = totalAmount
                })
                const regionItemIndex = reportData.findIndex(reportDataItem => reportDataItem.region_id === item.region_id)
                if (regionItemIndex !== -1) {
                  reportData.splice(regionItemIndex, 1)
                }
                reportData.push(regionItemObj)
              })
              this.stockData = reportData
              this.stockDataRegionWiseTotalStock = reportData
              this.fertilizerNameList.filter(fertilizerName => {
                let totalAmount = 0
                this.stockData.filter(regionItem => {
                  const fertilizer = regionItem.region_data.find(fertilizerItem => fertilizerItem.fertilizer_id === fertilizerName.value)
                  if (typeof fertilizer !== 'undefined') {
                    totalAmount += fertilizer.amount
                  }
                })
                this.regionWiseTotalStockGrandTotalObj[fertilizerName.value] += totalAmount
                this.regionWiseTotalStockTotalObj[fertilizerName.value] = totalAmount
              })
            }
          }
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    pdfExport () {
      // const rowData = this.getPdfData()
      // const labels = [
      //     { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
      //     { text: this.$t('fertilizerConfig.description'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      //   ]
      //  this.fertilizerNameList.filter(item => {
      //     const labelItem = {
      //       text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12)
      //     }
      //     labels.push(labelItem)
      //   })
      //  labels.push({ text: this.$t('fertilizerReport.total'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) })
      // const extraData = {
      //   totalHeaderRows: 1
      // }
      // rowData.unshift(labels)
      // const reportTitle = this.$i18n.locale === 'en' ? 'Current Stock Report' : 'বর্তমান স্টক রিপোর্ট'
      // const columnWids = '*'
      // ExportPdf.exportPdf(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, rowData, columnWids, extraData)
      const reportTitle = this.$i18n.locale === 'en' ? 'Current Stock Report' : 'বর্তমান স্টক রিপোর্ট'
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this)
    },
    getPdfData () {
      let keys = []

      keys = [
          { key: 'serial_no' },
          { key: this.currentLocale === 'en' ? 'description' : 'description_bn' }
        ]

      this.fertilizerNameList.filter(item => {
          keys.push({ key: 'fertilizer_amount_' + item.value })
      })
      keys.push({ key: 'total_amount' })
      var serial = 0
      const listData = this.stockDataTotalStock.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
          return { text: item[keyItem.key] }
        })
        return rowData
      })
      const footerRow = [
        { text: '' },
        this.currentLocale === 'en' ? { text: 'Gross Total :' } : { text: 'সর্ব মোট :' }
      ]
      this.fertilizerNameList.filter(fertilizerName => {
        footerRow.push({ text: this.totalStockReportTotalObj[fertilizerName.value] })
      })
      footerRow.push({ text: this.sum(this.totalStockReportTotalObj) })
      listData.push(footerRow)
      return listData
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
