import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
        {
            columns: reportHeadData.reportHeadColumn, style: 'main_head'
        },
        {
          text: vm.$t('fertilizerReport.krishi_bhaban'),
          style: 'krishi',
          alignment: 'center'
        },
        { text: reportHeadData.address, style: 'address', alignment: 'center' }
      ]
      pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right' })
      let allRows = []
      const fertlizerTableColumns = []
      vm.fertilizerNameList.filter(fertilizerName => {
        const fertlizerTableColumn = {
          text: vm.currentLocale === 'bn' ? fertilizerName.text_bn : fertilizerName.text_en,
          style: 'th',
          alignment: 'right'
        }
        fertlizerTableColumns.push(fertlizerTableColumn)
      })
      const totalColumn = {
        text: vm.$t('fertilizerReport.total'),
        style: 'th',
        alignment: 'right'
      }
      fertlizerTableColumns.push(totalColumn)
      // table header
      if (vm.search.report_type === 1) {
        allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerConfig.description'), style: 'th', alignment: 'center' }
          ].concat(fertlizerTableColumns)
        ]
      } else if (vm.search.report_type === 2) {
        allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.region'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.nong'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.godown'), style: 'th', alignment: 'center' }
          ].concat(fertlizerTableColumns)
        ]
      } else {
        allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.region'), style: 'th', alignment: 'center' }
          ].concat(fertlizerTableColumns)
        ]
      }
      if (vm.search.report_type === 1) {
        // table body
        vm.stockDataTotalStock.filter((item, index) => {
         const rowItem = [
           { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
           { text: vm.currentLocale === 'en' ? item.description : item.description_bn, style: 'td', alignment: 'center' }
         ]
         vm.fertilizerNameList.filter(fertilizerName => {
           const fertilizerColumnValue = {
             text: vm.$n(item['fertilizer_amount_' + fertilizerName.value]),
             style: 'td',
             alignment: 'right'
           }
           rowItem.push(fertilizerColumnValue)
         })
         const totalColumnValue = {
           text: vm.$n(item.total_amount),
           style: 'td',
           alignment: 'right'
         }
         rowItem.push(totalColumnValue)
         allRows.push(rowItem)
       })
       // table footer
       const tableFooterRowItem = [
         { text: vm.$t('fertilizerReport.GrandTotalMTon'), style: 'th', colSpan: 2, alignment: 'right' },
         {}
       ]
       vm.fertilizerNameList.filter(fertilizerName => {
         const fertilizerColumnValue = {
           text: vm.$n(vm.totalStockReportTotalObj[fertilizerName.value]),
           style: 'th',
           alignment: 'right'
         }
         tableFooterRowItem.push(fertilizerColumnValue)
       })
       const grandTotalColumnValue = {
         text: vm.$n(vm.sum(vm.totalStockReportTotalObj)),
         style: 'th',
         alignment: 'right'
       }
       tableFooterRowItem.push(grandTotalColumnValue)
       allRows.push(tableFooterRowItem)
      } else if (vm.search.report_type === 2) {
        // table body
        vm.stockDataGodownWiseTotalStock.filter((stockDataItem, stockDataIndex) => {
          stockDataItem.region_data.filter((regionDataItem, regionDataIndex) => {
            if (regionDataIndex === 0) {
              const rowItem = [
                { text: vm.$n(stockDataIndex + 1), style: 'td', alignment: 'center', rowSpan: stockDataItem.region_data.length },
                { text: vm.currentLocale === 'en' ? stockDataItem.region_name : stockDataItem.region_name_bn, style: 'td', alignment: 'center', rowSpan: stockDataItem.region_data.length },
                { text: vm.$n(regionDataIndex + 1), style: 'td', alignment: 'center' },
                { text: vm.currentLocale === 'en' ? regionDataItem.godown_infos_name : regionDataItem.godown_infos_name_bn, style: 'td', alignment: 'center' }
              ]
              vm.fertilizerNameList.filter(fertilizerName => {
                const fertilizerColumnValue = {
                  text: vm.getFertilizerAmount(regionDataItem.godown_infos_data, fertilizerName.value),
                  style: 'td',
                  alignment: 'right'
                }
                rowItem.push(fertilizerColumnValue)
              })
              const totalColumnValue = {
                text: vm.$n(regionDataItem.godown_infos_total),
                style: 'td',
                alignment: 'right'
              }
              rowItem.push(totalColumnValue)
              allRows.push(rowItem)
            } else {
              const rowItem = [
                { text: '' },
                { text: '' },
                { text: vm.$n(regionDataIndex + 1), style: 'td', alignment: 'center' },
                { text: vm.currentLocale === 'en' ? regionDataItem.godown_infos_name : regionDataItem.godown_infos_name_bn, style: 'td', alignment: 'center' }
              ]
              vm.fertilizerNameList.filter(fertilizerName => {
                const fertilizerColumnValue = {
                  text: vm.getFertilizerAmount(regionDataItem.godown_infos_data, fertilizerName.value),
                  style: 'td',
                  alignment: 'right'
                }
                rowItem.push(fertilizerColumnValue)
              })
              const totalColumnValue = {
                text: vm.$n(regionDataItem.godown_infos_total),
                style: 'td',
                alignment: 'right'
              }
              rowItem.push(totalColumnValue)
              allRows.push(rowItem)
            }
          })
          // region total
          const regionRowItem = [
            { text: (vm.currentLocale === 'en' ? stockDataItem.region_name : stockDataItem.region_name_bn) + ' ' + vm.$t('globalTrans.total'), style: 'th', colSpan: 4, alignment: 'right' },
            {},
            {},
            {}
          ]
          vm.fertilizerNameList.filter(fertilizerName => {
            const fertilizerColumnValue = {
              text: vm.$n(stockDataItem.region_total_obj[fertilizerName.value]),
              style: 'th',
              alignment: 'right'
            }
            regionRowItem.push(fertilizerColumnValue)
          })
          const regionTotalColumnValue = {
            text: vm.$n(vm.sum(stockDataItem.region_total_obj)),
            style: 'th',
            alignment: 'right'
          }
          regionRowItem.push(regionTotalColumnValue)
          allRows.push(regionRowItem)
        })
        // godown wise stock total
        const godownWiseToalSockTotalItem = [
          { text: vm.$t('globalTrans.total'), style: 'th', colSpan: 4, alignment: 'right' },
          {},
          {},
          {}
        ]
        vm.fertilizerNameList.filter(fertilizerName => {
          const fertilizerColumnValue = {
            text: vm.$n(vm.godownWiseTotalStockTotalObj[fertilizerName.value]),
            style: 'th',
            alignment: 'right'
          }
          godownWiseToalSockTotalItem.push(fertilizerColumnValue)
        })
        const regionTotalColumnValue = {
          text: vm.$n(vm.sum(vm.godownWiseTotalStockTotalObj)),
          style: 'th',
          alignment: 'right'
        }
        godownWiseToalSockTotalItem.push(regionTotalColumnValue)
        allRows.push(godownWiseToalSockTotalItem)
        // transported stock total
        const transportStockTotalItem = [
          { text: vm.$t('fertilizerReport.transported'), style: 'th', colSpan: 4, alignment: 'right' },
          {},
          {},
          {}
        ]
        vm.fertilizerNameList.filter(fertilizerName => {
          const fertilizerColumnValue = {
            text: vm.$n(vm.totalTranstportedInfoTotalObj[fertilizerName.value]),
            style: 'th',
            alignment: 'right'
          }
          transportStockTotalItem.push(fertilizerColumnValue)
        })
        const transportedTotalColumnValue = {
          text: vm.$n(vm.sum(vm.totalTranstportedInfoTotalObj)),
          style: 'th',
          alignment: 'right'
        }
        transportStockTotalItem.push(transportedTotalColumnValue)
        allRows.push(transportStockTotalItem)
        // Grand total
        const tableFooterRowItem = [
          { text: vm.$t('sitePreference.grossTotal'), style: 'th', colSpan: 4, alignment: 'right' },
          {},
          {},
          {}
        ]
        vm.fertilizerNameList.filter(fertilizerName => {
          const fertilizerColumnValue = {
            text: vm.$n(vm.godownWiseTotalStockGrandTotalObj[fertilizerName.value]),
            style: 'th',
            alignment: 'right'
          }
          tableFooterRowItem.push(fertilizerColumnValue)
        })
        const grandTotalColumnValue = {
          text: vm.$n(vm.sum(vm.godownWiseTotalStockGrandTotalObj)),
          style: 'th',
          alignment: 'right'
        }
        tableFooterRowItem.push(grandTotalColumnValue)
        allRows.push(tableFooterRowItem)
      } else {
        vm.stockDataRegionWiseTotalStock.filter((stockDataItem, stockDataIndex) => {
          const rowItem = [
            { text: vm.$n(stockDataIndex + 1), style: 'td', alignment: 'center' },
            { text: vm.currentLocale === 'en' ? stockDataItem.region_name : stockDataItem.region_name_bn, style: 'td', alignment: 'center' }
          ]
          vm.fertilizerNameList.filter(fertilizerName => {
            const fertilizerColumnValue = {
              text: vm.$n(stockDataItem.region_total_obj[fertilizerName.value]),
              style: 'td',
              alignment: 'right'
            }
            rowItem.push(fertilizerColumnValue)
          })
          const totalColumnValue = {
            text: vm.$n(vm.sum(stockDataItem.region_total_obj)),
            style: 'td',
            alignment: 'right'
          }
          rowItem.push(totalColumnValue)
          allRows.push(rowItem)
        })
        // region wise stock total
        const regionWiseToalSockTotalItem = [
          { text: vm.$t('globalTrans.total'), style: 'th', colSpan: 2, alignment: 'right' },
          {}
        ]
        vm.fertilizerNameList.filter(fertilizerName => {
          const fertilizerColumnValue = {
            text: vm.$n(vm.regionWiseTotalStockTotalObj[fertilizerName.value]),
            style: 'th',
            alignment: 'right'
          }
          regionWiseToalSockTotalItem.push(fertilizerColumnValue)
        })
        const regionTotalColumnValue = {
          text: vm.$n(vm.sum(vm.regionWiseTotalStockTotalObj)),
          style: 'th',
          alignment: 'right'
        }
        regionWiseToalSockTotalItem.push(regionTotalColumnValue)
        allRows.push(regionWiseToalSockTotalItem)
        // transported stock total
        const transportStockTotalItem = [
          { text: vm.$t('fertilizerReport.transported'), style: 'th', colSpan: 2, alignment: 'right' },
          {}
        ]
        vm.fertilizerNameList.filter(fertilizerName => {
          const fertilizerColumnValue = {
            text: vm.$n(vm.totalTranstportedInfoTotalObj[fertilizerName.value]),
            style: 'th',
            alignment: 'right'
          }
          transportStockTotalItem.push(fertilizerColumnValue)
        })
        const transportedTotalColumnValue = {
          text: vm.$n(vm.sum(vm.totalTranstportedInfoTotalObj)),
          style: 'th',
          alignment: 'right'
        }
        transportStockTotalItem.push(transportedTotalColumnValue)
        allRows.push(transportStockTotalItem)
        // Grand total
        const tableFooterRowItem = [
          { text: vm.$t('sitePreference.grossTotal'), style: 'th', colSpan: 2, alignment: 'right' },
          {}
        ]
        vm.fertilizerNameList.filter(fertilizerName => {
          const fertilizerColumnValue = {
            text: vm.$n(vm.regionWiseTotalStockGrandTotalObj[fertilizerName.value]),
            style: 'th',
            alignment: 'right'
          }
          tableFooterRowItem.push(fertilizerColumnValue)
        })
        const grandTotalColumnValue = {
          text: vm.$n(vm.sum(vm.regionWiseTotalStockGrandTotalObj)),
          style: 'th',
          alignment: 'right'
        }
        tableFooterRowItem.push(grandTotalColumnValue)
        allRows.push(tableFooterRowItem)
      }
        pdfContent.push({
          table: {
            headerRows: 1,
            // widths: ['7%', '20%', '20%', '20%', '20%', '20%'],
            widths: '*',
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fontSize: 10,
              margin: [3, 3, 3, 3]
            },
            td: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 10,
            margin: [0, 10, 0, 10]
          },
          fertilizer: {
            margin: [0, 7, 0, 7]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, 1, 0, 15],
            alignment: 'center'
          },
          report_type: {
            fontSize: 9,
            margin: [0, 2, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('current-stock-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
